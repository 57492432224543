import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/common/seo';
import HeroBanner from '../components/molecules/hero-banner';
import DynamicZone from '../components/organisms/dynamic-zone';

const Page = ({
  data: {
    strapi: { page: data },
  },
}) => (
  <>
    <Seo {...data.seo} />
    {data.image && <HeroBanner data={data} />}
    <DynamicZone containers={data.containers} />
  </>
);

export const query = graphql`
  query Page($id: ID!) {
    strapi {
      page(id: $id) {
        name
        ...PageHeroBanner
        containers {
          ... on STRAPI_ComponentContainersText {
            __typename
            ...Text
          }
          ... on STRAPI_ComponentContainersAltText {
            __typename
            ...AltText
          }
          ... on STRAPI_ComponentContainersStats {
            __typename
            id
          }
          ... on STRAPI_ComponentContainersFeatureText {
            __typename
            ...FeatureText
          }
          ... on STRAPI_ComponentContainersQuote {
            __typename
            ...Quote
          }
          ... on STRAPI_ComponentContainersDocuments {
            __typename
            ...Documents
          }
          ... on STRAPI_ComponentContainersPartners {
            __typename
            ...Partners
          }
        }
        seo {
          ...Seo
        }
      }
    }
  }
`;

export default Page;
